import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Slider from "react-slick";
import SupportedDeviceSection from './SupportedDeviceSection';
import QuestionAswerSection from './QuestionAswerSection';
import image from '../../images/landing/image1.jpeg';
// import image2 from '../../images/landing/latest2.jpg';
// import image3 from '../../images/landing/latest3.jpg';
// import image4 from '../../images/landing/latest4.jpg';

var landingImages = [image];

const Landing = () => {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
    };

    return (
        <div className="pageWrapper searchPageMain">
            <div className="topContainer">
                <div className="landingPageWrapper closeMenuWrapper">
                    <Slider {...settings}>
                        {
                            landingImages.map((item, index) => {
                                return (
                                    <div key={index} >
                                        <div className="lpBannerSection" style={{
                                            backgroundImage: `url(${item})`,
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: '100%'
                                        }}>
                                            <div className="lpBannerwrapper">
                                                <div className="Xd2tG"></div>
                                                <div className="container lpMainContainer">
                                                    <div className="row lpSectionWrapper">
                                                        <div className="col col-12 col-md-12 lpFlexCenter">
                                                            <Link to="/register">
                                                                <button className="button buttonLarge watchBottom" >
                                                                    <div className="buttonBg" style={{backgroundColor: '#148AB7'}}></div>
                                                                    <div className="buttonContent">Start Watching</div>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Slider>
                    <div className="lpWhiteSection">
                        <SupportedDeviceSection />
                        {/* <QuestionAswerSection />
                        <div className="getAccountSection">
                            <div className="container lpMainContainer">
                                <div className="row lpSectionWrapper lpFlexWrapper">
                                    <div className="col col-12 col-md-6 lpFlexCenter">
                                        <h2>Get an account today</h2>
                                        <div className="lpSectionFont gacTextColor lpSectionTextCenter">Access free content on all of your devices, sync your list and continue watching anywhere.</div>
                                        <button className="button buttonLarge buttonSecondary" onClick={() => {
                                            history.push(
                                                { pathname: '/register' }
                                            )
                                        }}>
                                            <div className="buttonBg"></div>
                                            <div className="buttonContent">Register Free</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div >
    );
}
export default Landing;
