import React, { useState, useEffect } from "react";
import CategoryContainer from "./CategoryContainer";
import { useSelector, useDispatch } from "react-redux";
import { service } from "../../network/service";
import LiveContainer from "./LiveContainer";
import LiveSchedule from "./LiveSchedule";
import Notification from "../../common/Notification";

const Home = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState([]);
  const [newArrival, setNewArrival] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [categoryOrgLength, setCategoryOrgLength] = useState([]);
  const [freeCategory, setFreeCategory] = useState([]);
  const signInBlock = useSelector((state) => state.signInBlock);
  const login = useSelector((state) => state.login);
  const [fullCategory, setFullCategory] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    var singleObj = [];
    var freeArray = [];
    service.freeVideos().then((response) => {
      let freeObj = {};
      freeObj.category_id = 140;
      freeObj.category_name = "Free Videos";
      freeObj.shows = response.data;
      freeArray.push(freeObj);
      setFreeCategory(freeArray);
    });
    service.getRecentlyAddedShows().then((response) => {
      let newObj = {};
      newObj.category_id = 141;
      newObj.category_name = "New Releases";
      newObj.shows = response.data;
      singleObj.push(newObj);
      setNewArrival(singleObj);
    });
    service.getshowsbyCategory().then((response) => {
      if (response.success === true && response.data.length > 0) {
        setCategoryOrgLength(response.data.length);
        var data = response.data;
        // data.map((item, index) => {
        //     if (index < 4) {
        //         singleObj.push(item);
        //     }
        // })
        setFullCategory(data);
        dispatch({ type: "SET_CATEGORIES", payload: data });
        setCategory(data.slice(0, 4));
      }
    });
  }, [login]);

  const loadMoreCategory = () => {
    setLoadMore(true);
    setCategory(fullCategory);
  };
  const updateFuction = () => {
    var singleObj = [];
    var freeArray = [];
    service.freeVideos().then((response) => {
      let freeObj = {};
      freeObj.category_id = 140;
      freeObj.category_name = "Free Videos";
      freeObj.shows = response.data;
      freeArray.push(freeObj);
      setFreeCategory(freeArray);
    });
    service.getRecentlyAddedShows().then((response) => {
      let newObj = {};
      newObj.category_id = 141;
      newObj.category_name = "New Releases";
      newObj.shows = response.data;
      singleObj.push(newObj);
      setNewArrival(singleObj);
    });
    if (loadMore === true) {
      service.getshowsbyCategory().then((response) => {
        if (response.success === true && response.data.length > 0) {
          setCategoryOrgLength(0);
          var data = response.data;
          setCategory(data);
        }
      });
    } else {
      var singleObj = [];
      service.getshowsbyCategory().then((response) => {
        if (response.success === true && response.data.length > 0) {
          setCategoryOrgLength(response.data.length);
          var data = response.data;
          data.map((item, index) => {
            if (index < 4) {
              singleObj.push(item);
            }
          });
          setCategory(singleObj);
        }
      });
    }
  };

  return (
    <div className="pageWrapper searchPageMain">
      <div className="topContainer">
        <div className="homepageWrapper menuCloseJS closeMenuWrapper">
          {signInBlock === true ? <Notification /> : null}
          <LiveContainer />
          <LiveSchedule />
          {/*<PartnerContainer /> */}
          <div className="allCategoryContainer">
            {freeCategory.map((freeCategory, index) => {
              if (freeCategory.show_count !== "0") {
                return (
                  <div key={index}>
                    <CategoryContainer
                      param={freeCategory}
                      clickHandler={updateFuction}
                    />
                  </div>
                );
              }
            })}
            {newArrival.map((newCategory, index) => {
              if (newCategory.show_count !== "0") {
                return (
                  <div key={index}>
                    <CategoryContainer
                      param={newCategory}
                      clickHandler={updateFuction}
                    />
                  </div>
                );
              }
            })}
            {category
              .filter((item) => item.shows.length != 0)
              .map((category, index) => {
                if (category.show_count !== "0") {
                  return (
                    <div key={index}>
                      <CategoryContainer
                        param={category}
                        clickHandler={updateFuction}
                      />
                    </div>
                  );
                }
              })}
            {categoryOrgLength > 4 && !loadMore && (
              <div className="container" onClick={loadMoreCategory}>
                <div className="row loadMoreContainer">
                  <button className="button buttonLarge buttonSecondary">
                    <div className="buttonBg"></div>
                    <div className="buttonContent">Load More</div>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
