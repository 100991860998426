import React, { useState, useEffect } from 'react';
import image from '../../../images/advertise/experiance.jpg';
const AdvertiseWithUs = () => {
    window.scrollTo(0, 0);
    useEffect(() => {
	}, []);
    return (
        <div className="menuCloseJS closeMenuWrapper">
            <div id="content" className="site-content">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <article id="post-27" className="post-27 page type-page status-publish hentry">
                            <div className="entry-content">
                                <div className="vc_row wpb_row vc_row-fluid vc_custom_1584031025503 bb_custom_1584031025503">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="adventure-header">
                                                    <div id="main-bg" className="main-bg" style={{ top: '0px' }}></div>
                                                    <div className="header-content">
                                                        <h1>Let your Brand Stand Out with Adventure Sports TV<span>
                                                            The Future of TV is Free. Align your brand with the millions of highly engaged viewers discovering free streaming.</span></h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element  normal w_780  wpb_animate_when_almost_visible wpb_slideInUp slideInUp test-class1 test-class2 container adventure-content wpb_start_animation animated" id="test_id">
                                                    <div className="wpb_wrapper">
                                                        <h2>Why Adventure Sports TV</h2>
                                                        <p><span style={{ fontWeight: '400' }}>
                                                            Our mission is to provide viewers with access to the largest library of premium movies and TV shows, all for free. We believe that personalized viewing experiences across quality, curated content helps brands amplify their message in front of the consumers who matter most as they stream what they love.</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid vc_custom_1581450206425">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner vc_custom_1586820513875">
                                            <div className="wpb_wrapper">
                                                <div className="adventure-service-list-wrapper ">
                                                    <div className="wpb_text_column wpb_content_element  normal normal   container adventure-service-list">
                                                        <div className="wpb_wrapper">
                                                            <div className="service-item ">
                                                                 <img src={require('../../..//images/advertise/premium-content.png')}  alt="" style={{ width: '100%' }} />
                                                                <div>
                                                                    <h5 style={{ color: '#26262d' }}>Brand Safe, Premium Content</h5>
                                                                    <p style={{ color: '#707c86' }}>100% premium, professionally produced movies and TV shows from trusted studios and networks with over 20K+ diverse titles that carry with them MPAA and TV Parental Guidelines.</p>
                                                                </div>
                                                            </div>
                                                            <div className="service-item "> 
                                                            <img src={require('../../..//images/advertise/impactful-enviroments.png')}  alt="" style={{ width: '100%' }} />
                                                                <div>
                                                                    <h5 style={{ color: '#26262d' }}>Massive Audience Reach</h5>
                                                                    <p style={{ color: '#707c86' }}>Connect with over 25 Million Monthly Active Users across 25+ platforms and devices, with low duplication across other streaming services and premium cable networks.</p>
                                                                </div>
                                                            </div>
                                                            <div className="service-item ">
                                                                 <img src={require('../../..//images/advertise/engaged-audiences.png')}  alt="" style={{ width: '100%' }} />
                                                                <div>
                                                                    <h5 style={{ color: '#26262d' }}>Advanced Targeting and Measurement</h5>
                                                                    <p style={{ color: '#707c86' }}>Minimize waste and maximize reach with advanced frequency management, data-driven audience targeting and measurement solutions for optimal return on your investments.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="half-page-position" className="vc_row wpb_row vc_row-fluid vc_custom_1596016433165">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid vc_custom_1578573634779">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element   normal normal  vc_custom_1581602957907 ad-experience container adventure-text-banner"  style={{ backgroundImage: `url(${image})`,paddingBottom: '60px' }}>
                                                    <div className="wpb_wrapper">
                                                        <h2><img src={require('../../../images/advertise/ad-counter.svg')}
                                                         alt="" />Experience</h2>
                                                        <p>Tell your brand story through full screen, immersive environments and make meaningful connections with
                                                        <br /> our viewers.</p> <a className="btn white " href="https://corporate.adventuretv.com/partners/advertisewithus/ad-experience/" title="Legal" target="">
                                                            <span>Learn More</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid vc_custom_1578573724366">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="wpb_single_image wpb_content_element vc_align_center  vc_custom_1583773001819  anywhere container">
                                                    <figure className="wpb_wrapper vc_figure ">
                                                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                                                            <img width="1526" height="314" src={require("../../../images/advertise/experiance.jpg")}
                                                                className="vc_single_image-img attachment-full" alt=""
                                                                srcSet="https://corporate.adventuretv.com/wp-content/uploads/2020/03/advertise-anywhere-devices.png 1526w, https://corporate.adventuretv.com/wp-content/uploads/2020/03/advertise-anywhere-devices-300x62.png 300w, https://corporate.adventuretv.com/wp-content/uploads/2020/03/advertise-anywhere-devices-1024x211.png 1024w, https://corporate.adventuretv.com/wp-content/uploads/2020/03/advertise-anywhere-devices-768x158.png 768w"
                                                                sizes="(max-width: 1526px) 100vw, 1526px" />
                                                        </div>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element  normal w_780  test-class1 test-class2 container adventure-content" id="test_id">
                                                    <div className="wpb_wrapper">
                                                        <h2>Advertise Anywhere</h2>
                                                        <p>Advertising with Adventure Sports TV is available on Android, iOS, Roku, Amazon Fire TV and the web.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className=" adventure-gallery container">
                                                    <div className="wpb_wrapper">
                                                        <div className=" wpb_image_grid" data-itemwidth="400" data-interval="3">
                                                            <ul className="image_grid" style={{maxWidth: '600px'}}>
                                                                <li className="">
                                                                    <a className="prettyphoto" data-rel="prettyPhoto[rel-27-1201345469]">
                                                                        <img src={require('../../../images/advertise/icon-android.svg')}  className="attachment-full" alt="" /></a>
                                                                </li>
                                                                <li className="">
                                                                    <a className="prettyphoto" href="https://channelstore.roku.com/details/9aae5dd01c2467862bb962b68b8b70e9/Adventure Sports TV" data-rel="prettyPhoto[rel-27-1201345469]">
                                                                        <img src={require('../../../images/advertise/icon-roku.svg')}  className="attachment-full" alt="" /></a>
                                                                </li>
                                                                <li className="">
                                                                    <a className="prettyphoto" href="https://apps.apple.com/in/app/Adventure Sports TV/id1535463535" data-rel="prettyPhoto[rel-27-1201345469]">
                                                                        <img src={require('../../../images/advertise/icon-iOS.svg')}  className="attachment-full" alt="" /></a>
                                                                </li>
                                                                {/* <li className="">
                                                                    <a className="prettyphoto" href="./images/advertise/icon-apple-tv.svg" data-rel="prettyPhoto[rel-27-1201345469]">
                                                                        <img src={require('../../../images/advertise/icon-apple-tv.svg')}  className="attachment-full" alt="" /></a>
                                                                </li> */}
                                                                <li className="">
                                                                    <a className="prettyphoto" href="./images/advertise/icon-amazon-fire.svg" data-rel="prettyPhoto[rel-27-1201345469]">
                                                                        <img src={require('../../../images/advertise/icon-amazon-fire.svg')}  className="attachment-full" alt="" /></a>
                                                                </li>
                                                                {/* <li className="">
                                                                    <a className="prettyphoto" href="./images/advertise/icon-xbox.svg" data-rel="prettyPhoto[rel-27-1201345469]">
                                                                        <img src={require('../../../images/advertise/icon-xbox.svg')}  className="attachment-full" alt="" /></a>
                                                                </li>
                                                                <li className="">
                                                                    <a className="prettyphoto" href="./images/advertise/icon-samsung.svg" data-rel="prettyPhoto[rel-27-1201345469]">
                                                                        <img src={require('../../../images/advertise/icon-samsung.svg')}  className="attachment-full" alt="" /></a>
                                                                </li>
                                                                <li className="">
                                                                    <a className="prettyphoto" href="./images/advertise/icon-sony.svg" data-rel="prettyPhoto[rel-27-1201345469]">
                                                                        <img src={require('../../../images/advertise/icon-sony.svg')}  className="attachment-full" alt="" /></a>
                                                                </li>
                                                                <li className="">
                                                                    <a className="prettyphoto" href="./images/advertise/icon-ps4.svg" data-rel="prettyPhoto[rel-27-1201345469]">
                                                                        <img src="./images/advertise/icon-ps4.svg" className="attachment-full" alt="" /></a>
                                                                </li>
                                                                <li className="">
                                                                    <a className="prettyphoto" href="./images/advertise/icon-xfinity.svg" data-rel="prettyPhoto[rel-27-1201345469]">
                                                                        <img src={require('../../../images/advertise/icon-xfinity.svg')}  className="attachment-full" alt="" /></a>
                                                                </li>
                                                                <li className="">
                                                                    <a className="prettyphoto" data-rel="prettyPhoto[rel-27-1201345469]">
                                                                        <img src={require('../../../images/advertise/icon-cox.svg')}  className="attachment-full" alt="" /></a>
                                                                </li>
                                                                <li className="">
                                                                    <a className="prettyphoto" href="./images/advertise/icon-vizio.svg" data-rel="prettyPhoto[rel-27-1201345469]">
                                                                        <img src={require('../../../images/advertise/icon-vizio.svg')} className="attachment-full" alt="" /></a>
                                                                </li> */}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner vc_custom_1578648045732">
                                            <div className="wpb_wrapper">
                                                <div className="modal-popup-box" data-bodybg="rgba(0,0,0,0.39)" style={{textAlign: 'center'}}>
                                                    <button className="model-popup-btn popup-337" data-id="popup-337" style={{color: '#26262d',background: '#ffffff' ,borderRadius: '2px' ,fontSize: '14px', padding: '14px 61px'}}>
                                                        <i style={{paddingRight: '5px'}} className="fa " aria-hidden="true"> </i> Get Started
                                                      </button>
                                                </div>
                                                {/* <style>
                                            .modal-popup-box .popup-337:hover {
                                                color: #26262d !important;
                                                background: #ffffff !important;
                                            }
                                            </style> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </main>
                </div>
            </div>
        </div>
    );
}
export default AdvertiseWithUs;
