import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from '../components/Home/Home';
import Movies from '../components/Movies/Movies';
import CategoryList from '../components/CategoryList/CategoryList';
import PartnerShows from '../components/PartnerShows/PartnerShows';
import RecentlyAdded from '../components/RecentlyAdded/RecentlyAdded';
import SignIn from '../components/SignIn/SignIn';
import Register from '../components/Register/Register';
import Series from '../components/Series/Series';
import AboutUs from '../components/FooterComponents/AboutUs/AboutUs';
import Search from '../components/Search/Search';
import Header from '../components/Basic/Header';
import Footer from '../components/Basic/Footer';
import SubscriptionList from '../components/SubscriptionList/SubscriptionList';
import Landing from '../components/Landing/Landing';
import Success from '../components/Payment/Succes';
import Error from '../components/Payment/Error';
import Payment from '../components/Payment/Payment';
import VideoPlayer from '../components/Video/VideoPlayer';
import TermsOfUse from '../components/FooterComponents/TermsOfUse/TermsOfUse';
import PrivacyPolicy from '../components/FooterComponents/PrivacyPolicy/PrivacyPolicy';
import PressRelease from '../components/FooterComponents/PressRelease/PressRelease';
import AdvertiseWithUs from '../components/FooterComponents/AdvertiseWithUs/AdvertiseWithUs';
import Contact from '../components/FooterComponents/Contact/Contact';
import ContactSupport from '../components/FooterComponents/ContactSupport/ContactSupport';
import SupportedDevices from '../components/FooterComponents/SupportedDevices/SupportedDevices';
import PartnerList from '../components/PartnerList/PartnerList';
import MySubscription from '../components/MySubscription/MySubscription';
import Account from '../components/Account/Account';
import ManageDevice from '../components/ManageDevice/ManageDevice';
import BilingActivity from '../components/BilingActivity/BilingActivity';
import Signout from '../components/Signout/Signout';
import ChangePassword from '../components/ChangePassword/changePassword';
import Activate from '../components/Activate/Activate';

const routes = () => (
    <Router>
        <Header />
        <div className="closeMenuWrapper">
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/home" exact component={Home} />
                <Route path="/home/movies" exact component={Movies} />
                <Route path="/home/categorylist" exact component={CategoryList} />
                <Route path="/home/partnershows" exact component={PartnerShows} />
                <Route path="/home/recentlyadded" exact component={RecentlyAdded} />
                <Route path="/signin" exact component={SignIn} />
                <Route path="/register" exact component={Register} />
                <Route path="/series" exact component={Series} />
                <Route path="/aboutus" exact component={AboutUs} />
                <Route path="/search" exact component={Search} />
                <Route path="/subscription" exact component={SubscriptionList} />
                <Route path="/success" exact component={Success} />
                <Route path="/error" exact component={Error} />
                <Route path="/payment" exact component={Payment} />
                <Route path="/videoplayer" exact component={VideoPlayer} />
                <Route path="/termsofuse" exact component={TermsOfUse} />
                <Route path="/policydarkmode" exact component={PrivacyPolicy} />
                <Route path="/pressrelease" exact component={PressRelease} />
                <Route path="/advertisewithus" exact component={AdvertiseWithUs} />
                <Route path="/contactus" exact component={Contact} />
                <Route path="/contactsupport" exact component={ContactSupport} />
                <Route path="/supportdevice" exact component={SupportedDevices} />
                <Route path="/partnerList" exact component={PartnerList} />
                <Route path="/mysubscription" exact component={MySubscription} />
                <Route path="/account" exact component={Account} />
                <Route path="/manageDevice" exact component={ManageDevice} />
                <Route path="/bilingActivity" exact component={BilingActivity} />
                <Route path="/signout" exact component={Signout} />
                <Route path="/changePassword" exact component={ChangePassword} />
                <Route path="/tv" exact component={Activate} />
            </Switch>
        </div>
        <Footer />
    </Router>

);
export default routes;
